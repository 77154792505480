import React,{useState,useEffect} from 'react'
import { Row,Col,Card, CardBody} from "shards-react";
import { Table  } from "antd";
import PageTitle from '../_components/PageTitle';
import axios from "axios";
import { API_URL } from "../_config";
import { store } from "../_helpers";
import ModalOfertSelect from './ModalOfertSelect'
import ModalDeleteOfert from './ModalDeleteOfert'
import Cookies from "universal-cookie";
import './Cotizaciones.css'

export const Cotizaciones = () => {
  const cookies = new Cookies();
  const [modalSelect, setModalSelect] = useState(false)
  const [EliminadoOVerificadoId, setEliminadoOVerificadoId ] = useState("")
  const [idData, setIdData] = useState([])
  const [modalSelectDelete, setModalSelectDelete] = useState(false)
  const [ofertasActivas, setOfertasActivas] = useState([])

  useEffect(() => {
    axios({
      method: "get",
      url:
        API_URL + "api/cardeals/" + cookies.get("dealer").id + "?token=" +
        store.getState().authentication.user.token
    }).then(res => setOfertasActivas(res.data.map(o => ({  
      id: o.id,
      email: o.email,
      whatsapp: o.whatsapp,
      localidad: o.localidad,
      comentario:o.comentario,
      lat: o.lat,
      lng:o.lng,
      año: o.year,
      marca: o.marca,
      version : o.version,
      modelo: o.modelo,
      estado: o.estado,
      status: o.status,
      accion: o.id
  }))))
  }, [EliminadoOVerificadoId])


    const columns = [
        {
          title: "Marca",
          dataIndex: "marca",
          key: "marca",
          sorter: (a, b) => a.marca.localeCompare(b.marca),
        },
        {
          title: "Modelo",
          dataIndex: "modelo",
          key: "modelo",
          sorter: (a, b) => a.modelo.localeCompare(b.modelo),
        },
        {
          title: "Año",
          dataIndex: "año",
          key: "año",
          sorter: (a, b) => a.año - b.año,
        },
        {
          title: "Estado",
          dataIndex: "estado",
          key: "estado",
          sorter: (a, b) => a.estado.localeCompare(b.estado),
        },
        {
          title: "Fecha",
          dataIndex: "fecha",
          key: "date",
          sorter: (a, b) => a.date.localeCompare(b.date)
        },
        {
            title: "Acción",
            dataIndex: "accion",
            key: "accion",
            render: id => (
              <td className="pof" style={{ padding: "1rem !important" }}>
              <i
              onClick={() => { return setIdData(ofertasActivas.filter(o => o.id === id)),setModalSelect(true)}}
                className="material-icons"
                title="Ver datos"
                style={{ cursor: "pointer", display: "inline", fontSize: "23px" }}
              >
                assignment
              </i>
              <i
              onClick={() => { return setIdData(ofertasActivas.filter(o => o.id === id)),setModalSelectDelete(true)}}
                className="material-icons"
                title="Eliminar"
                style={{ cursor: "pointer", display: "inline", fontSize: "23px" }}
              >
                delete
              </i>
            </td>
            )
          }
      ];
      console.log(ofertasActivas,"OFERTAS")
return (
    <div>
         <Row noGutters className="page-header py-4" style={{ width: "100%" }}>
          <PageTitle sm="5" title="Ofertas Activas" className="text-sm-left" />
          <Col sm="5"></Col>
          <Col sm="2">
          </Col>
        </Row>
          <Row>
          <Col>
            <Card small className="mb-4">
              <CardBody className="p-0 pb-3">
                <div className="table-responsive">
                  <Table
                    columns={columns}
                    locale={{ emptyText: "Obteniendo Ofertas..." }}
                    dataSource={ofertasActivas}
                    pagination={{ pageSize: 20 }}
                    rowClassName={ (record, index) => record.status === 1 ? "data-row": ""   }
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <ModalOfertSelect tokenId={store.getState().authentication.user.token} idAgency={cookies.get("dealer").id} setEliminadoOVerificadoId={setEliminadoOVerificadoId} idData={idData} modalSelect={modalSelect} setModalSelect={setModalSelect}/>
      <ModalDeleteOfert idData={idData} tokenId={store.getState().authentication.user.token} idAgency={cookies.get("dealer").id} modalSelectDelete={modalSelectDelete} setModalSelectDelete={setModalSelectDelete} setEliminadoOVerificadoId={setEliminadoOVerificadoId}    />
    </div>
)
}