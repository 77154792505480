//import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout, GuestLayout, Empty as EmptyLayout } from "./layouts";

import { Home } from "./Home";
import { Dashboard } from "./Dashboard";
import { Vehicles } from "./Vehicles";
import { myDealer } from "./myDealer";
import { EditVehicles } from "./Vehicles";
import { LinkingPage } from "./LinkingPage";
import { RankingSociosPage } from "./RankingSociosPage";
import { SelectDealerPage } from "./SelectDealerPage/SelectDealerPage";
import { Usuarios } from "./Usuarios/Usuarios";
import { ABMFormularios } from "./ABMFormularios/ABMFormularios";
import { AdminPedidoDeInformes } from "./ABMCategoriasInformes/AdminPedidoDeInformes";
import { Perfiles } from "./Perfiles/Perfiles";
import { CrearUsuario } from "./Usuarios";
import { ConsUsuario } from "./Usuarios";
import { LoginPage } from "./LoginPage";
import { RedNuestrosAutos } from "./RedNuestrosAutos";
import { FichaContainer } from "./RedNuestrosAutos/FichaRedNuestrosAutos/FichaContainer";
import { Concesionarias } from "./Concesionarias";
import { Formularios } from "./Formularios";
import { myProfile } from "./myProfile";
import { Ayuda } from "./Ayuda";
import { PedidoInformes } from "./PedidoInformes";
import { DashboardGeneral } from "./DashboardGeneral";
import { SegurosPage } from "./Seguros/SegurosPage";
import { Panel } from "./Panel";
import { Cotizaciones } from "./Cotizaciones/Cotizaciones";
import { Consultas } from "./Consultas";
import { ConsultasView } from "./Consultas";
import { Notificaciones } from "./Notificaciones";
import { NotificacionesListado } from "./NotificacionesListado";
import { NewHome } from "./NewHome";
import {Permisos} from "./Permisos";
import {MergeBrandsAndModelsContainer} from "./MergeBrands/MergeBrandsAndModelsContainer";
import {MergeItems} from "./MergeBrands/MergeItems";
//import { SubirImagenes } from "./TestComponent/SubirImagenes";
export default [
  {
    path: "/login",
    layout: GuestLayout,
    component: LoginPage
  },
  {
    path: "/notificaciones",
    layout: DefaultLayout,
    categoria: "CotizadorSeguros",
    permiso: "list",
    private: true,
    component: NotificacionesListado
  },
  /*
  { 
    path: "/usuarios",
    layout: DefaultLayout,
    component: Usuarios
  },
  {
    path: "/resumen-concesionaria",
    layout: DefaultLayout,
    component: Dashboard
  },
  */
  {
    path: "/seguros",
    layout: DefaultLayout,
    categoria: "CotizadorSeguros",
    permiso: "list",
    private: true,
    component: SegurosPage
  },
  {
    path: "/mydealer",
    layout: DefaultLayout,
    component: myDealer,
    categoria: "MiConcesionaria",
    permiso: "list",
    private: true,
  },
  {
    path: "/home",
    layout: GuestLayout,
    component: Home
  },
  {
    path: "/vehiculos/editar/:id",
    layout: DefaultLayout,
    private: true,
    exact: true,
    component: EditVehicles
  },
  {
    path: "/detalle/:id",
    layout: DefaultLayout,
    private: true,
    exact: true,
    component: FichaContainer
  },
  {
    path: "/vehiculos",
    layout: DefaultLayout,
    admin: true,
    categoria: "Vehiculos",
    permiso: "list",
    exact: true,
    private: true,
    component: Vehicles
  },
  {
    path: "/dashboard",
    layout: DefaultLayout,
    private: true,
    component: Dashboard
  },
  {
    path: "/vinculaciones",
    layout: DefaultLayout,
    categoria: "Vinculaciones",
    permiso: "list",
    private: true,
    component: LinkingPage
  },
  {
    path: "/ranking",
    layout: DefaultLayout,
    categoria: "RankingSocios",
    permiso: "list",
    private: true,
    component: RankingSociosPage
  },
  {
    path: "/select_dealer",
    layout: EmptyLayout,
    component: SelectDealerPage
  },
  {
    path: "/usuarios/crear",
    layout: DefaultLayout,
    private: true,
    component: CrearUsuario
  },
  {
    path: "/usuarios/cons",
    layout: DefaultLayout,
    private: true,
    component: ConsUsuario
  },
  {
    path: "/usuarios",
    exact: true,
    admin: true,
    categoria: "Usuarios",
    permiso: "list",
    private: true,
    layout: DefaultLayout,
    component: Usuarios
  },
  {
    path: "/permisos",
    exact: true,
    admin: true,
    categoria: "Permisos",
    permiso: "list",
    private: true,
    layout: DefaultLayout,
    component: Permisos
  },
  {
    path: "/abm_formularios",
    exact: true,
    admin: true,
    categoria: "AbmFormularios",
    permiso: "list",
    private: true,
    layout: DefaultLayout,
    component: ABMFormularios
  },
  {
    path: "/red",
    exact: true,
    categoria: "RedNuestrosAutos",
    permiso: "list",
    layout: DefaultLayout,
    private: true,
    component: RedNuestrosAutos
  },
  {
    path: "/concesionarias",
    exact: true,
    admin: true,
    categoria: "Concesionarias",
    permiso: "list",
    private: true,
    layout: DefaultLayout,
    component: Concesionarias
  },
  {
    path: "/formularios",
    exact: true,
    layout: DefaultLayout,
    categoria: "Formularios",
    permiso: "list",
    private: true,
    component: Formularios
  },
  {
    path: "/miperfil",
    exact: true,
    categoria: "MiPerfil",
    permiso: "list",
    layout: DefaultLayout,
    private: true,
    component: myProfile
  },
  {
    path: "/pedidoinformes",
    exact: true,
    categoria: "PedidoDeInformes",
    permiso: "list",
    layout: DefaultLayout,
    private: true,
    component: PedidoInformes
  },
  {
    path: "/admin_informes",
    admin: true,
    exact: true,
    categoria: "AbmFormularios",
    permiso: "list",
    private: true,
    layout: DefaultLayout,
    component: AdminPedidoDeInformes
  },
  {
    path: "/admin_listas_uni",
    admin: true,
    exact: true,
    categoria: "AbmFormularios",
    permiso: "list",
    private: true,
    layout: DefaultLayout,
    component: MergeBrandsAndModelsContainer
  },
  {
    path: "/admin_listas_uni/:id",
    admin: true,
    exact: true,
    categoria: "AbmFormularios",
    permiso: "list",
    private: true,
    layout: DefaultLayout,
    component: MergeItems
  },
  {
    path: "/perfiles",
    exact: true,
    admin: true,
    categoria: "Perfiles",
    permiso: "list",
    layout: DefaultLayout,
    private: true,
    component: Perfiles
  },
  {
    path: "/admin_ayuda",
    exact: true,
    categoria: "MensajesAyuda",
    permiso: "list",
    layout: DefaultLayout,
    private: true,
    component: Ayuda
  },
  {
    path: "/dashboard_general",
    layout: DefaultLayout,
    private: true,
    component: DashboardGeneral
  },
  {
    path: "/panel",
    layout: DefaultLayout,
    private: true,
    categoria: "Panel",
    permiso: "list",
    component: Panel
  },
  {
    path: "/cotizaciones",
    layout: DefaultLayout,
    private: true,
    categoria: "Cotizaciones",
    permiso: "list",
    component: Cotizaciones
  },
  {
    path: "/consultas/view/:id",
    layout: DefaultLayout,
    private: true,
    exact: true,
    component: ConsultasView
  },
  {
    path: "/consultas",
    layout: DefaultLayout,
    private: true,
    exact: true,
    component: Consultas
  },
  {
    path: "/admin_notificaciones",
    layout: DefaultLayout,
    categoria: "Notificaciones",
    permiso: "list",
    private: true,
    component: Notificaciones
  },
  {
    path: "/new-home",
    layout: GuestLayout,
    component: Home
  }
  /* {
    path: "/test",
    layout: GuestLayout,
    component: SubirImagenes
  }*/
];
