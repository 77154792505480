import Axios from 'axios';
import React from 'react'
import { Row,Col, ModalFooter, Modal,ModalBody,ModalHeader,} from "shards-react";
import { API_URL } from '../_config';

const ModalOfertSelect = ({idData,idAgency,modalSelect,tokenId,setModalSelect,setEliminadoOVerificadoId}) => {
    const idOfet= idData[0] && idData[0].id
    const OfertaVerificada= () => {
      Axios.post(`${API_URL}api/cardeals/${idOfet}/dealer/${idAgency}?token=${tokenId}`)
      .then(response => setEliminadoOVerificadoId(response.status))
      setModalSelect(!modalSelect)
    }
    return (
        <Modal
          size="lg"
          open={modalSelect}
          toggle={() => console.log("Toggle")}
        >
          <ModalHeader>
            Datos del Oferente 
          </ModalHeader>
          <ModalBody>
       <div>
           {idData.length > 0 && idData.map(o => (
            <Row key={o.id}>
         <Col>
         <div className="pb-2">
           <h6>Datos del Vehiculo:</h6>
         </div>
         <h6>Año:</h6>
         <p>{o.año}</p>
         <h6>Marca:</h6>
         <p>{o.marca}</p>
         <h6>Modelo:</h6>
         <p>{o.modelo}</p>
         <h6>Versión:</h6>
         <p>{o.version}</p>
         <h6>Estado del Vehiculo:</h6>
         <p>{o.estado}</p>
         <h6>Comentarios:</h6>
         <p>{o.comentario}</p>
         </Col>
            <Col>
         <div className="pb-2">
           <h6>Datos Personales:</h6>
         </div>
         <h6>Email:</h6>
         <p>{o.email}</p>
         <h6>Nombre y Apellido:</h6>
         <p>{o.name && o.name}</p>
         <h6>WhatsApp:</h6>
         <p>{o.whatsApp ? o.whatsApp : "Sin Número"}</p>
         <h6>Provincia:</h6>
         <p>Buenos Aires</p>
         <h6>Localidad:</h6>
         <p>{o.localidad}</p>
         </Col>
       </Row>
           ))}
       
       </div>
          </ModalBody>
          <ModalFooter>
          <div >
         <button className="btn btn-primary  m-2" onClick={OfertaVerificada}>Verificado</button>
         </div>
          </ModalFooter>
        </Modal>
        )
}

export default ModalOfertSelect;